/**
 * Report firing of specific actions to analytics endpoint.
 */
import _cloneDeep from "lodash/cloneDeep";
import Cookie from "js-cookie";
import QueryString from "query-string";
import { AnyObject, API } from "@thenounproject/lingo-core";
import { downloadFile } from "@actions/useDownloadFile";
import { Action, UnknownAction } from "redux";
import { NavPoint, setNavPoint } from "@redux/legacy-actions/navPoints";
import { trackColorUsage } from "@redux/actions/analytics/trackColorUsage";
import { signup } from "@redux/actions/auth/useSignup";
import { login } from "@redux/actions/auth/useLogin";
import { fetchCurrentUser } from "@queries/useCurrentUser";
import { downloadMutlipleAssets } from "@redux/actions/useDownloadMultipleAssets";
import { updateSubscription } from "@redux/actions/billing/useUpdateSubscription";
import { captureException } from "../../adapters/sentry";
import { generateFilecut } from "@redux/actions/useGenerateFilecut";
import { showModal } from "@redux/actions/useModals";
import { createSupportRequest } from "@redux/actions/billing/useCreateSupportRequest";
import { requestEnterpriseSubscription } from "@redux/actions/billing/useRequestEnterpriseSubscription";
import whatConverts from "../../adapters/whatConverts";
import { RootState } from "@redux/store";

// Middlewares aren't always called when loading the marketing site
// but we want to store utms before they are removed from the url.
storeUTM();

// Map action type to event name
function getInternalEvent(action: UnknownAction, navPoint: NavPoint) {
  if (generateFilecut.fulfilled.match(action)) {
    const {
      inspectable: {
        asset: { shareToken },
        item: { id: itemId, version } = {},
      },
      fileType,
      dimensions,
    } = action.meta.arg;

    return {
      event_name: "used_asset",
      data: {
        item_uuid: itemId,
        version: version,
        dimensions: dimensions,
        type: fileType,
        space_id: navPoint.spaceId,
        portal_uuid: navPoint.portalId,
        asset_token: shareToken,
      },
    };
  } else if (downloadMutlipleAssets.fulfilled.match(action)) {
    return action.meta.arg.inspectables
      .map(i => i.item)
      .filter(Boolean)
      .map(i => ({
        event_name: "used_asset",
        data: {
          item_uuid: i.id,
          version: i.version,
          space_id: navPoint.spaceId,
          portal_uuid: navPoint.portalId,
          asset_token: i.asset ? i.asset.shareToken : null,
        },
      }));
  } else if (trackColorUsage.match(action)) {
    const { item, format } = action.payload;
    return {
      event_name: "used_asset",
      data: {
        item_uuid: item.id,
        version: item.version,
        format: format,
        space_id: navPoint.spaceId,
        portal_uuid: navPoint.portalId,
        asset_token: item.asset?.shareToken,
      },
    };
  } else if (setNavPoint.match(action)) {
    const { spaceId, kitId, portalId, version } = action.payload,
      prevNavPoint = navPoint;
    if (!spaceId) return;
    if (
      prevNavPoint &&
      prevNavPoint.spaceId === spaceId &&
      prevNavPoint.kitId === kitId &&
      prevNavPoint.portalId === portalId
    )
      return;

    let event = "viewed_space";
    if (kitId) {
      event = "viewed_kit";
    } else if (portalId) {
      event = "viewed_portal";
    }
    return {
      event_name: event,
      data: {
        space_id: spaceId,
        kit_uuid: kitId,
        portal_uuid: portalId,
        version: kitId ? version : undefined,
      },
    };
  }
}

const gtmEvents: string[] = [
  downloadFile.type,
  signup.fulfilled.type,
  login.fulfilled.type,
  updateSubscription.fulfilled.type,
  generateFilecut.fulfilled.type,
  showModal.type,
  createSupportRequest.fulfilled.type,
  requestEnterpriseSubscription.fulfilled.type,
];
function actionToGtmEvent(action: UnknownAction, _navPoint: NavPoint) {
  if (!gtmEvents.includes(action.type)) return null;
  const clonedAction = _cloneDeep(action) as AnyObject;
  // Remove some data from the action
  clonedAction.response = undefined;
  if (typeof clonedAction.payload === "object") {
    clonedAction.payload.result = undefined;
    clonedAction.payload.entities = undefined;
  }
  const { type, ...actionData } = clonedAction;
  return { event: "Redux Action", type, actionData };
}

const analytics = store => next => (action: Action) => {
  storeUTM();
  const state = store.getState() as RootState,
    navPoint = state.navPoint;
  processAnalytics(action, navPoint || ({} as NavPoint));
  processIntercomEvent(action, state);
  return next(action);
};

// Store any utms in cookies for use any potential signup later
function storeUTM() {
  const qs = QueryString.parse(window.location.search);
  Object.keys(qs).forEach(k => {
    if (k.startsWith("utm")) {
      Cookie.set(k, qs[k] as string);
    }
  });
}

function processAnalytics(action: Action, navPoint: NavPoint) {
  const internalEvent = getInternalEvent(action, navPoint),
    gtmEvent = actionToGtmEvent(action, navPoint);

  if (internalEvent) void sendEventToLingo(internalEvent);
  if (gtmEvent) sendEventToGTM(gtmEvent);

  reportWhatConverts(action, navPoint);
}

function reportWhatConverts(action: Action, _navPoint: NavPoint) {
  if (signup.fulfilled.match(action) && !action.meta.arg.skipAutoJoin) {
    // skipAutoJoin is set when accepting invites, we don't want to track those as lead signups
    whatConverts.trackSignup({
      email: action.meta.arg.email,
      name: action.meta.arg.name,
      message: "",
    });
  }
}

function sendEventToGTM(data) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  window.dataLayer?.push?.(data);
}

async function sendEventToLingo(event) {
  if (!event) return;
  const events = Array.isArray(event) ? event : [event];
  if (!events.length) return;
  try {
    await API.call({
      endpoint: "analytics/events/",
      method: "POST",
      data: {
        events,
      },
    });
  } catch (err) {
    captureException(new Error(err.message ?? err));
  }
}

function processIntercomEvent(action: Action, state: RootState) {
  if (!window.Intercom) return;

  const updates: AnyObject = {};
  if (
    signup.fulfilled.match(action) ||
    login.fulfilled.match(action) ||
    fetchCurrentUser.fulfilled.match(action)
  ) {
    const { user: userId, intercom_id } = action.payload.result,
      user = action.payload.entities.users[userId];

    updates.user_hash = intercom_id;
    updates.name = user.name;
    updates.email = user.email;
    updates.user_id = String(user.id);
  } else if (setNavPoint.match(action)) {
    const navPoint = action.payload;
    const {
        entities: { spaces, kits },
      } = state,
      { kitId, spaceId } = navPoint,
      space = spaceId ? spaces.objects[spaceId] : null,
      spaceRole = space && space.access ? space.access.role : null,
      kit = kitId ? kits.objects[kitId] : null,
      kitRole = kit && kit.access ? kit.access.role : null;
    updates.active_space_role = spaceRole;
    updates.active_kit_role = kitRole;
  }
  if (Object.keys(updates).length) {
    window.Intercom("update", updates);
  }
}

export default analytics;
