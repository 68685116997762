import { useAppDispatchV1, useAppSelectorV1 } from "@redux/hooks";
import { useEffect, useRef, useCallback } from "react";
import { APIResponse, Item, ItemType } from "@thenounproject/lingo-core";
import { fetchItem } from "@redux/actions/items/useItem";
import { ASSET_POLL_DELAY } from "@redux/middleware/polling";

export function usePollItemAsset(item: Item) {
  const dispatch = useAppDispatchV1();
  const isPolling = useRef(false);
  const isDoneProcessing = useRef(false);
  const isIntersecting = useRef(false); // Track visibility status

  const { id: itemId, spaceId, version, asset } = item;
  const { shareToken: assetToken } = asset ?? {};

  const assets = useAppSelectorV1(state => state.entities.assets.objects);
  const items = useAppSelectorV1(state => state.entities.items.objects);

  const storedAsset = assets?.[item.assetId];
  const storedItem = items[`${itemId}-${version}`];
  const isManualGallery =
    typeof item.data.itemProcessing === "undefined" && item.type === ItemType.gallery;

  const assetComplete = storedAsset?.meta?.assetProcessing === "complete";
  const itemComplete = storedItem?.data?.itemProcessing === "complete";

  const pollAsset = useCallback(async () => {
    if (
      isManualGallery ||
      isPolling.current ||
      assetComplete ||
      itemComplete ||
      isDoneProcessing.current ||
      !isIntersecting.current
    ) {
      return;
    }

    isPolling.current = true;

    const res = await dispatch(fetchItem({ args: { spaceId, assetToken, itemId, version } }));

    const payload = res.payload as APIResponse;
    const isComplete =
      payload?.entities?.assets?.[item.assetId]?.meta?.assetProcessing === "complete" ||
      payload?.entities?.items?.[`${itemId}-${version}`]?.data?.itemProcessing === "complete";
    if (isComplete) {
      isDoneProcessing.current = true;
    } else {
      setTimeout(pollAsset, ASSET_POLL_DELAY);
    }

    isPolling.current = false;
  }, [
    dispatch,
    spaceId,
    assetToken,
    itemId,
    version,
    assetComplete,
    itemComplete,
    item?.assetId,
    isManualGallery,
  ]);

  const observeElement = useCallback(
    (element: HTMLElement | null) => {
      if (!element || assetComplete || itemComplete) return;

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          isIntersecting.current = entry.isIntersecting;
          if (entry.isIntersecting && !isDoneProcessing.current) {
            void pollAsset();
          }
        });
      });

      observer.observe(element);
      return () => observer.disconnect();
    },
    [assetComplete, itemComplete, pollAsset]
  );

  useEffect(() => {
    const element = document.querySelector(
      `[data-itemid="${item.id}-${item.version}"]`
    ) as HTMLElement;
    observeElement(element);

    return () => {
      isPolling.current = false;
      isDoneProcessing.current = false;
    };
  }, [item.id, item.version, observeElement]);
}
